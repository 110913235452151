<template>
  <div class="com-post-video-wrapper">
    <div
      v-show="status === 1"
      class="status-play"
    >
      <i class="ui-loading-bright" />
      <div class="video-play" />
      <div class="flow-info">
        <div class="flow-play" />34MB流量
      </div>
    </div>
    <div
      v-show="status === 2"
      class="status-change"
    >
      <div class="info">
        <div class="pic-xx" />
        <div class="txt">
          <p>
            视频正在火速转码中～
            <br><span>请稍后再试</span>
          </p>
        </div>
      </div>
      <div class="flow-info">
        <i class="icon-retry" />刷新重试
      </div>
    </div>
    <VideoPlayer
      :play-info="playInfo"
    />
  </div>
</template>

<script>
import VideoPlayer from '@/component/video-player/index.vue';

export default {
  components: {
    VideoPlayer,
  },
  props: {
    videoInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      status: 0,
    };
  },
  computed: {
    playInfo() {
      if (this.videoInfo.source === 2 && this.videoInfo.vid) {
        return {
          type: 'txv',
          vid: this.videoInfo.vid,
        };
      }
      const videoDetail = this.videoInfo.detail;
      if (Array.isArray(videoDetail) && videoDetail[0] && videoDetail[0].appId && videoDetail[0].fileId) {
        return {
          type: 'txy',
          appId: videoDetail[0].appId,
          fileId: videoDetail[0].fileId,
          psign: videoDetail[0].psign,
        };
      }
      if (videoDetail[0] && videoDetail[0].source) {
        return {
          type: 'other',
          url: videoDetail[0].source,
        };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
