<template>
  <DefaultLayout title="闪现一下">
    <PostLoading
      v-if="pageLoading"
    />
    <PostError
      v-else-if="postError"
      :text="postError"
      :status="postErrorStatus"
    />
    <div
      v-else
      class="page-post-video"
    >
      <VideoWrapper
        v-if="showVideo"
        :video-info="videoInfo"
        class="video-wrapper"
      />
      <NoticeBar
        v-if="Number(postInfo.status) === 2"
        type="warning"
      >
        视频审核中，仅自己可见，稍后刷新页面查看
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 4"
        type="error"
      >
        视频审核中，仅自己可见，稍后刷新页面查看
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 7"
        type="error"
      >
        视频被管理员下架
      </NoticeBar>
      <postItem
        :uid="creatorInfo.uid"
        :avatar="creatorInfo.iconUrl"
        :nickname="creatorInfo.nickName"
        :user-level="creatorInfo.level"
        :follow-status="creatorInfo.followStatus"
        :account-type="creatorInfo.accountType"
        :vip-type="creatorInfo.vipType"
        :title="postInfo.title"
        :view-num="postInfo.readCnt"
        :content="postInfo.content"
        :publish-time="Number(postInfo.createTime)"
        :current-time="parseInt(new Date().getTime() / 1000)"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :data-source="dataSource"
        :post-main="'video'"
        @changeUserFollowStatus="onChangeUserFollowStatusHandler"
      />
      <GroupEntry
        v-if="groupInfo && !isFromGroup"
        :type="isFromGroup?'simple':'default'"
        :group-info="groupInfo"
        class="page-post-video-group-entry"
      />
      <div
        v-if="(groupInfo && isFromGroup) || ($compareVersion('1.2') > -1 && topicInfo && topicInfo.title)"
        class="group-info"
      >
        <GroupEntry
          v-if="groupInfo && isFromGroup"
          :type="isFromGroup?'simple':'default'"
          :group-info="groupInfo"
        />
        <IconLabel
          v-if="$compareVersion('1.2') > -1 && topicInfo && topicInfo.title"
          :text="topicInfo.title"
          @click.native="$checkAppDeco(onTopicClickHandler, topicInfo.id);onTopicClickHandlerReport()"
          @updated.once="onTopicLabelLayout"
          icon="topic"
          style="max-width:154px"
        />
      </div>
      <PostWelfare
        v-if="!isApp"
        :gift-number="giftNumber"
        :group-info="groupInfo"
        :welfare-info="welfareInfo"
        class="post-welfare"
      />
      <!-- <div class="gap" /> -->
      <PostComment
        ref="postCommentRef"
        :anchor-watcher="[postInfo]"
        :post-main="'video'"
        :post-id="Number(postId)"
        :comment-total-number="postInfo.commentCnt"
        :author-info="creatorInfo"
        :report-content-type="reportContentType"
        :group-info="groupInfo"
        @updateCommentAmount="onUpdateCommentAmountHandler"
        class="post-comment"
      />
      <PostFooter
        ref="postFooterRef"
        v-if="isApp"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :post-action="postAction"
        @postComment="onPostCommentHandler"
        @getPostAction="onGetPostAction"
        @update="onUpdatePostFooterHandler"
        @commentIconClick="onCommentIconClick"
        @inputTipsShow="onInputTipsShow"
        @inputTipsClick="onInputTipsClick"
        class="post-footer ui-border-t"
      />
      <Dialog
        v-model="deleteDialogStatus"
        :confirm="true"
        :confirm-text-style="{color: '#303133'}"
        :confirm-text="'确认删除'"
        @confirm="onDeleteDialogConfirmHandler"
      >
        确认要删除吗？
      </Dialog>
      <PostAccuse
        v-model="accusePopupStatus"
        :post-main="'video'"
        :post-info="postInfo"
      />
      <ShareDownload
        v-if="!isApp"
        :channel="'sxnrshipin'"
      />
      <ManageDialog
        v-model="isShowManageDialog"
        :page-type="'video'"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :group-info="groupInfo"
        @update="onManageUpdate"
      />
    </div>
  </DefaultLayout>
</template>

<script>
import { mapState } from 'vuex';
import DefaultLayout from '@/layouts/default/index.vue';
import postItem from '@/component/post-item/index.vue';
import PostComment from '@/component/post-comment/index.vue';
import PostFooter from '@/component/post-footer/index.vue';
import PostLoading from '@/component/post-loading/index.vue';
import GroupEntry from '@/component/group-entry/index.vue';
import PostError from '@/component/post-error/index.vue';
import PostWelfare from '@/component/post-welfare/index.vue';
import ShareDownload from '@/component/share-download/index.vue';
import IconLabel from '@/ui/label/icon-label.vue';
import NoticeBar from '@/ui/notice-bar/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import PostAccuse from '@/component/post-accuse/index.vue';
import postApi from '@/api/post';
import postDetail from '@/plugin/mixin/postDetail';
import postReportData from '@/plugin/mixin/postReportData';
import { getShareUrl } from '@/util/get-base-url';
import { reportEnum } from '@/api/report';
import {
  postComment, setRightButton, showShareDialog, showShareDialogV2, popBack,
  send as noticeSend, openView, sendGlobalNotification,
} from '@/jsbridge';
import { screenYAxisScroll, getUrlQuery } from '@/util/util';
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import { setH5Share } from '@/util/h5-share';
import compareVersion from '@/util/compareVersion';
import aegis from '@/util/aegis';
import unicodeString from '@/util/unicode-string';
import VideoWrapper from './component/video-wrapper/index.vue';
import { pageStore, PAGE_STORE_MODULE_NAME, pageMixin } from './store';
import ManageDialog from '@/component/post-manage/manage-dialog/index.vue';

export default {
  name: 'PagePostVideo',
  components: {
    VideoWrapper,
    postItem,
    PostFooter,
    PostComment,
    IconLabel,
    PostLoading,
    PostError,
    PostWelfare,
    ShareDownload,
    NoticeBar,
    PostAccuse,
    Dialog,
    DefaultLayout,
    GroupEntry,
    ManageDialog,
  },
  mixins: [postDetail, postReportData, pageMixin],
  data() {
    return {
      pageError: false,
      postId: '',
      postType: 1,
      // postInfo: null,
      // videoInfo: null,
      // creatorInfo: null,
      // groupInfo: null,
      // postAction: null,
      bottomDistance: 60,
      newComment: {},
      // 删除帖子
      deletePostLoading: false,
      deleteDialogStatus: false,
      // 举报帖子
      accusePopupStatus: false,
      reportPageId: reportEnum.pageid.POST_VIDEO,
      reportContentType: reportEnum.contenttype.POST_VIDEO,
      pageFrom: '',
      retryTimer: null,
      retryCount: 0,
      maxRetryCount: 0,
      pageErrorText: '系统繁忙',
      postErrorStatus: 'fail',
      pageLoaded: false,
      isFromGroup: false, // 是否从圈子打开, http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237859949769
      isShowManageDialog: false,
      prevId: '', // 上级来源页ID
    };
  },

  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
      hasPerm: state => state.manager?.hasPerm,
    }),
    isUserEqualToAuthor() {
      const isHave = this.userInfo && this.userInfo.uid && this.postInfo;
      return isHave && (Number(this.userInfo.uid) === Number(this.postInfo.creatorId));
    },
    postError() {
      if (this.pageError || !this.postInfo) {
        return this.pageErrorText;
      }
      if (this.postInfo && this.postInfo.status === 2 && !this.isUserEqualToAuthor) {
        return '内容正在审核';
      }
      if (this.postInfo && this.postInfo.status === 4 && !this.isUserEqualToAuthor) {
        return '内容审核未通过';
      }
      if (this.postInfo && this.postInfo.status === 7 && !this.isUserEqualToAuthor) {
        return '内容被管理员下架';
      }
      if (this.postInfo && this.postInfo.status === 9) {
        return '内容已被删除';
      }
      if ([2, 4, 6, 7, 9].indexOf(Number(this.postInfo.status)) === -1) {
        return `状态异常: ${this.postInfo.status}`;
      }
      return false;
    },
    videoInfo() {
      return this.postInfo ? this.postInfo.videoInfo : null;
    },
    // 是否从沉浸式进入
    isFromImmersive() {
      return String(this.pageFrom) === '1510';
    },
    showVideo() {
      const isFromKK = getUrlQuery()?.source === 'kk';

      return (isFromKK || !this.isApp) && this.videoInfo;
    },
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (this.pageLoaded) {
        if (newVal && !oldVal) {
          // 从无到有就是登录
          this.initPageData({
            silent: true,
          });
        }
      }
    },
    videoInfo(newVal) {
      if (newVal && this.pageReportParams) {
        this.pageReportParams.ext3 = this.postInfo.videoInfo.duration;
      }
    },
    isFromGroup(val) {
      logger.debug('isFromGroup', val, this.groupInfo);
    },
  },
  storeName: PAGE_STORE_MODULE_NAME,
  storeModules: {
    [PAGE_STORE_MODULE_NAME]: pageStore,
  },
  asyncData({ store, route }, preData) {
    if (!store.state[PAGE_STORE_MODULE_NAME]) {
      store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    const { postId } = route.query;
    return store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
      params: {
        postId,
        postType: 1,
      },
      preData,
    });
  },
  created() {
    this.pageFrom = this.$route.query.pageFrom;
  },
  mounted() {
    // const searchStr = window.location.search;
    // const searchObj = util.qs.parse(searchStr, { ignoreQueryPrefix: true });
    this.resetData();
    // console.log(window.location.href);
    this.prevId = this.$route.query.prev_id;
    this.postId = this.$route.query.postId;
    this.isFromGroup = this.$route.query.pageFrom === '1306';
    this.postType = 1;
    // this.pageLoading = true;
    if (this.postId) {
      if (this.isApp) {
        this.$store.dispatch('user/getUserInfo')
          .then(() => {
            if (this.userInfo) {
              this.initPageData();
            } else {
              this.initPageDataNotLogin();
            }
          })
          .catch((err) => {
            logger.err('getUserInfo', err);
          });
      } else {
        this.reportPageId = reportEnum.pageid.POST_VIDEO_H5;
        this.initPageDataH5();
      }
    } else {
      this.pageLoading = false;
      this.pageError = true;
      aegis.report('详情页打开异常');
      // this.pageErrorText = '系统繁忙(URL)';
    }
  },
  beforeDestroy() {
    clearTimeout(this.retryTimer);
    this.pageError = false;
    this.resetData();
  },
  methods: {
    onManageUpdate() {
      this.getPostInfo({
        params: {
          flag: 2,
        },
      });
    },
    resetData() {
      this.pageError = false;
      this.postId = '';
      this.postType = 1;
      this.bottomDistance = 60;
      this.newComment = {};
      this.deletePostLoading = false;
      this.deleteDialogStatus = false;
      this.accusePopupStatus = false;
      this.reportPageId = reportEnum.pageid.POST_VIDEO;
      this.reportContentType = reportEnum.contenttype.POST_VIDEO;
      this.retryTimer = null;
      this.retryCount = 0;
      this.maxRetryCount = 0;
      this.pageErrorText = '系统繁忙';
      this.postErrorStatus = 'fail';
      this.pageLoaded = false;
    },
    initPageData({ silent = false } = {}) {
      Promise.all([this.getPostInfo()])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              ext1: this.isFromImmersive ? 1 : 0,
              prev_id: this.prevId,
            }, '视频详情页曝光');
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.$nextTick(() => {
              this.initPageDom();
            });
            this.setRightButton();
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageData({ silent: true });
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            if (!silent) {
              this.pageError = true;
              aegis.report('详情页打开异常');
            }
          }
          return true;
        });
    },
    initPageDataNotLogin() {
      Promise.all([this.getPostInfo()])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              ext1: this.isFromImmersive ? 1 : 0,
              prev_id: this.prevId,
            }, '视频详情页曝光');
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.$nextTick(() => {
              this.initPageDom();
            }, 0);
            this.setRightButton();
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataNotLogin();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        })
        .catch((err) => {
          logger.warn('getPostInfo', err);
        });
    },
    initPageDataH5() {
      Promise.all([this.getPostInfo({
        params: {
          flag: 2,
        },
      })])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              ext1: this.isFromImmersive ? 1 : 0,
              prev_id: this.prevId,
            }, '视频详情页曝光');
            this.reportData({
              pageid: '2000',
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
            }, '所有内容详情页页面曝光');
            setH5Share({
              shareTitle: this.postInfo.title || '闪现一下',
              shareDesc: unicodeString.slice(this.postInfo.summary, 200) || '超多精彩游戏内容，尽在闪现一下',
              shareLink: getShareUrl(),
              shareImgUrl: (this.videoInfo && this.videoInfo.coverUrl) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
            });
            this.$nextTick(() => {
              this.initPageDom();
            }, 0);
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataNotLogin();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        })
        .catch((err) => {
          logger.warn('getPostInfo', err);
        });
    },
    getPostInfo({ params = {} } = {}) {
      const { postId } = this.$route.query;
      return new Promise((resolve) => {
        this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
          params: {
            postId,
            postType: 1,
            userId: this.userInfo ? this.userInfo.uid : 0,
            ...params,
          },
        }).then((res) => {
          if (res.info && res.info[0] && res.info[0].postInfo) {
            return resolve(res);
          }
          logger.error('getPostInfo error', res);
          // this.pageErrorText = '系统繁忙(null)';
          return resolve('error');
        }, (err) => {
          logger.error('getPostInfo error', err);
          this.postErrorStatus = 'fail';
          if (err.status === 200 && err.data) {
            this.pageErrorText = '系统繁忙';
          } else if (err.response) {
            this.pageErrorText = '系统繁忙';
          } else if (err.request) {
            this.pageErrorText = '网络竟然崩溃了，换个姿势刷新试试';
            this.postErrorStatus = 'network';
          } else {
            this.pageErrorText = '系统繁忙';
          }
          return resolve('error');
        });
      });
    },
    getPostAction() {
      return new Promise((resolve) => {
        postApi.getPostAction({
          userId: this.userInfo.uid,
          postId: this.postId,
        }).then(res => resolve(res), () => resolve('error'));
      });
    },
    onGetPostAction() {
      this.getPostAction()
        .then((res) => {
          if (res !== 'error') this.postAction = res;
        });
    },
    setRightButton() {
      if (this.postInfo.status === 9) return false;
      setRightButton({
        callback: () => {
          // 右上角按钮点击上报
          this.reportData({
            pageid: '1501',
            moduleid: 1,
            eventtype: 3,
            extendid: 1,
          }, '视频详情页点击右上角按钮');
          // if (this.$store.state.isAndroid) {
          //   if (!this.userInfo) {
          //     this.$checkAuthDeco(() => {
          //       this.onGetPostAction();
          //     });
          //     return false;
          //   }
          //   if ((!this.postAction || Object.keys(this.postAction).length === 0)) {
          //     this.onGetPostAction();
          //     this.$toast('系统繁忙，请稍候再试');
          //     return false;
          //   }
          // }
          const action = [
            {
              type: 'follow',
              name: this.postAction && this.postAction.ifFollow ? '已收藏' : '收藏',
              native: 0,
            },
          ];
          if (this.userInfo && Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
            action.push({
              type: 'delete',
              name: '删除',
              native: 0,
            });
          } else {
            action.push({
              type: 'accuse',
              name: '举报',
              native: 0,
            });
            action.push({
              type: 'block',
              name: '屏蔽',
              native: 0,
            });
          }
          action.push({
            type: 'copyLink',
            name: '复制链接',
            native: 1,
          });
          let showShareDialogFunc = showShareDialog;
          if (this.$compareVersion('1.8.2') >= 0) {
            showShareDialogFunc = showShareDialogV2;
            // action = [{
            //   type: 'tgFriend',
            // }].concat(action);
            if (this.hasPerm) { // todo 管理员判断
              action.push({
                type: 'custom',
                title: '管理内容',
                icon: decodeURIComponent('https://static.gameplus.qq.com/business/iconh5/manage/icon_94_manege.png'),
                id: 1,
                native: 0,
              });

              this.reportData({
                operid: '1501000830201',
              }, '内容详情页操作面板管理按钮曝光');
            }
          }
          const shareContent = {
            shareVisible: this.postInfo.status === 6 ? 1 : 0, // 0 分享不可见， 1 可见
            title: this.postInfo.title || '闪现一下',
            summary: unicodeString.slice(this.postInfo.summary, 200) || '超多精彩游戏内容，尽在闪现一下',
            targetUrl: getShareUrl(),
            thumbUrl: (this.videoInfo && this.videoInfo.coverUrl) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
            action,
            postInfo: {
              postId: this.postId,
              postType: this.postType,
              postCreatorId: this.postInfo.creatorId,
              groupId: this.postInfo.groupId,
              action: this.postAction && this.postAction.ifFollow ? 'follow' : 'unfollow',
              userId: (this.userInfo && this.userInfo.uid) || 0,
            },
          };
          logger.info('showShareDialog', shareContent);
          showShareDialogFunc(shareContent)
            .then((res2) => {
              // console.log(`showShareDialog: ${JSON.stringify(res2)}`);
              if (res2.result === 0) {
                switch (res2.data.type) {
                  case 'follow':
                    this.$checkAuthDeco(() => {
                      this.$refs.postFooterRef.onCollectHandler();
                    });
                    break;
                  case 'delete':
                    this.$checkAuthDeco(() => {
                      this.deleteDialogStatus = true;
                    });
                    break;
                  case 'accuse':
                    if (checkEnv().isSwitchOn) {
                      this.accusePopupStatus = true;
                    } else {
                      this.$checkAuthDeco(() => {
                        this.accusePopupStatus = true;
                      });
                    }
                    break;
                  case 'block':
                    postApi.postAction({
                      postId: this.postInfo.id,
                      postType: this.postInfo.type,
                      postCreatorId: this.postInfo.creatorId,
                      userId: (this.userInfo && this.userInfo.uid) || 0,
                      groupId: this.postInfo.groupId,
                      action: 'block',
                    }).then(() => {
                      this.$toast('将为你减少此内容推荐');
                    })
                      .catch((err) => {
                        logger.err('postAction', err);
                        this.$toast('将为你减少此内容推荐');
                      });
                    break;
                  case 'WXSession':
                    this.sendNotice({
                      operate: 'share',
                      data: 'WXSession',
                    });
                    break;
                  case 'WXTimeline':
                    this.sendNotice({
                      operate: 'share',
                      data: 'WXTimeline',
                    });
                    break;
                  case 'QQFriend':
                    this.sendNotice({
                      operate: 'share',
                      data: 'QQFriend',
                    });
                    break;
                  case 'QQZone':
                    this.sendNotice({
                      operate: 'share',
                      data: 'QQZone',
                    });
                    break;
                  case 'custom':
                    if (parseInt(res2.data.id, 10) === 1) {
                      this.isShowManageDialog = true;
                      this.reportData({
                        operid: '1501000830301',
                      }, '内容详情页操作面板管理按钮点击');
                    }
                    break;


                  default:
                    break;
                }
              }
              // 处理上报
              if (res2.data.type) {
                switch (res2.data.type) {
                  case 'follow':
                    break;
                  case 'delete':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 32,
                      eventtype: 3,
                      extendid: 1,
                    }, '帖子详情页点击删除按钮');
                    break;
                  case 'accuse':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 12,
                      eventtype: 3,
                      extendid: 1,
                    }, '帖子详情页点击举报');
                    break;
                  case 'WXSession':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 25,
                      eventtype: 3,
                      extendid: 2,
                    }, '分享到微信点击');
                    break;
                  case 'WXTimeline':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 25,
                      eventtype: 3,
                      extendid: 3,
                    }, '分享到微信朋友圈点击');
                    break;

                  case 'QQFriend':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 25,
                      eventtype: 3,
                      extendid: 4,
                    }, '分享到QQ好友点击');
                    break;
                  case 'QQZone':
                    this.reportData({
                      pageid: '1501',
                      moduleid: 25,
                      eventtype: 3,
                      extendid: 5,
                    }, '分享到QQ空间点击');
                    break;
                  case 'custom':
                    if (parseInt(res2.data.id, 10) === 1) {
                      this.reportData({
                        operid: '1501000830301',
                      }, '内容详情页操作面板管理按钮点击');
                    }
                    break;
                  default:
                    break;
                }
              }
            });
          return true;
        },
      });
      return true;
    },
    initPageDom() {
      // alert(this.$refs.postFooterRef);
      this.bottomDistance = this.$refs.postFooterRef ? this.$refs.postFooterRef.$el.offsetHeight : 0;
    },
    onPostCommentHandler() {
      if (this.reportData) {
        this.reportData({
          moduleid: 15,
          eventtype: 3,
          extendid: 2,
        }, '视频详情页底部评论框点击');
      }
      let attribute; // 1:普通，2:作者，3:官方，4:置顶
      // accountType:0 为普通用户，1 官方一级 2 官方二级 3 官方个人 4 媒体官方 5 媒体个人
      if (Number(this.userInfo.accountType) > 0 && Number(this.userInfo.accountType) < 4) {
        attribute = 3;
      } else if (Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        attribute = 2;
      } else {
        attribute = 1;
      }
      let { postCommentRef } = this.$refs;
      if (Array.isArray(postCommentRef)) [postCommentRef] = postCommentRef;
      postComment({
        type: 2,
        parentId: this.postId,
        rootId: this.postId,
        commentatorId: this.creatorInfo.uid,
        attribute,
        content: '',
        nickname: this.creatorInfo.nickName,
        groupId: this.postInfo.groupId,
      }).then((commentData) => {
        // console.log('postCommentData', commentData);
        // 终端弹窗
        postCommentRef.addCommentItem({
          commentData,
        });
        this.reportData({
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '视频详情页评论成功');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '所有详情页评论成功');
      });
    },
    onUpdateCommentAmountHandler({ num } = {}) {
      const commentCnt = this.postInfo.commentCnt + Number(num);
      this.postInfo = {
        ...this.postInfo,
        commentCnt,
      };
      this.sendNotice({
        operate: 'updateCommentAmount',
        data: this.postInfo.commentCnt,
      });
      this.sendNotice({
        operate: 'commentCnt',
        data: this.postInfo.commentCnt,
      });
    },
    onChangeUserFollowStatusHandler(payload) {
      this.creatorInfo = {
        ...this.creatorInfo,
        followStatus: payload,
      };
    },
    // 更新底部点赞收藏内容
    onUpdatePostFooterHandler(action) {
      this.postAction = action;
    },
    // 点击底部评论按钮
    onCommentIconClick() {
      let { postCommentRef } = this.$refs;
      if (Array.isArray(postCommentRef)) [postCommentRef] = postCommentRef;
      const commentBCR = postCommentRef.$el.getBoundingClientRect();
      const commentTop = commentBCR.top || 0;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
      const headerHeight = clientWidth * (9 / 16); // 9/16的视频顶部高度
      const footerHeight = this.$refs.postFooterRef.$el.offsetHeight;
      const footerOffset = 40; // 底部距离偏差，防止”评论“只露出一点点的情况，没有滑动
      const isCommentInView = commentTop <= (clientHeight - footerHeight - footerOffset);

      // 1.如果评论数为0 或 已经在评论处，点击评论按钮，拉起输入框
      if (!this.postInfo.commentCnt || isCommentInView) {
        this.$checkAuthDeco(this.onPostCommentHandler);
      } else {
        // 2. 如果未在评论处，点击评论按钮快速定位评论处
        const scrollDistance = commentTop - headerHeight - footerHeight;
        screenYAxisScroll({ scrollDistance });
      }
      this.onCommentIconClickReport();
    },
    onCommentIconClickReport() {
      this.reportData({
        pageid: 1503,
        moduleid: 15,
        eventtype: 3,
        extendid: 5,
      }, '视频详情页底部评论点击');
    },
    // 删除帖子
    onDeleteDialogConfirmHandler() {
      if (this.deletePostLoading) return false;
      this.deletePostLoading = true;
      postApi.deletePost({
        id: this.postId,
      }).then(() => {
        this.$toast('删除成功');
        this.sendNotice({
          operate: 'delete',
          data: '',
        });
        popBack();
      })
        .catch(() => {
          this.$toast('删除失败');
        })
        .finally(() => {
          this.deletePostLoading = false;
        });
      return true;
    },
    // 通知其他页面
    sendNotice({
      operate, // 操作，delete postComment postAnswer postAction
      data = '',
    }) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_updatePost', {
          operate,
          id: this.postId,
          data,
        });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'webUpdatePost',
          noticeParams: {
            operate,
            id: this.postId,
            data,
          },
        }).then(() => {
          // console.log('发送hippy通知成功', res);
        });
      }
    },
    onGroupClickHandler() {
      this.$linkToGroup(this.groupInfo.id);
    },
    onGroupClickReport() {
      this.reportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
      }, '视频详情页所属圈子点击');
    },
    onTopicClickHandler(topicId) {
      openView({
        options: `tgc://native?moduleName=topic&topicId=${topicId}`,
      });
    },
    onTopicLabelLayout() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '所有内容详情页话题入口曝光');
      this.reportData({
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '视频详情页话题入口曝光');
    },
    onTopicClickHandlerReport() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '所有内容详情页话题入口点击');
      this.reportData({
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '视频详情页话题入口点击');
    },
    onInputTipsShow() {
      this.reportData({
        pageid: 1503,
        moduleid: 15,
        eventtype: 2,
        extendid: 6,
      }, '视频详情页底部评论引导气泡曝光');
    },
    onInputTipsClick() {
      this.reportData({
        pageid: 1503,
        moduleid: 15,
        eventtype: 3,
        extendid: 6,
      }, '视频详情页底部评论引导气泡点击');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
